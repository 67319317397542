(function (w, d) {

  // begin object.closest polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this;

      do {
        if (Element.prototype.matches.call(el, s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
  // end object.closest polyfill
  if (typeof ScrollTrigger !== 'undefined' && typeof DrawSVGPlugin !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);
  }

  function setActiveMenuItem() {

    let pathname = w.location.pathname,
      page = pathname.split(/[/ ]+/).pop(),
      menuItems = d.querySelectorAll('#menu-main-menu a');

    for (let i = 0; i < menuItems.length; i++) {

      const item = menuItems[i],
        itemHrefs = item.getAttribute("href"),
        itemParent = item.closest('li');

      if (page === itemHrefs) {

        itemParent.classList.add("active");

        let siblings = itemParent.parentNode.children;

        for (let j = 0; j < siblings.length; j++) {

          const sibling = siblings[j];
          if (itemParent !== sibling) {
            sibling.classList.remove('active');
          }

        }
      }

    }
  }

  let menuInit = {

    page_wrapper: d.getElementById('pageWrapper'),
    responsive_trigger: d.querySelector('.zn-res-trigger'),
    back_text: '<li class="zn_res_menu_go_back"><a href="#"><span class="zn_res_back_icon fal fa-chevron-left"></span>Back</a></li>',
    close_text: '<li class="zn_res_menu_go_back"><a href="#"><span class="zn_res_back_icon fal fa-times"></span>Close</a></li>',
    phone_text: '<li class="mobile_menu_phone"><a href="tel:7704458923"><span class="fas fa-phone"></span> 770-445-8923</a></li>',
    cloned_menu: d.getElementById('menu-main-menu').cloneNode(true),
    menu_activated: false,

    init: function () {

      this.cloned_menu.setAttribute("id", "zn-res-menu");
      this.cloned_menu.setAttribute("class", "");

      this.triggerMenu();
    },


    start_responsive_menu: function () {

      let responsive_menu = this.page_wrapper.appendChild(this.cloned_menu);

      function set_height() {
        let _menu = d.querySelector('.zn-menu-visible');

        if (_menu) {
          _menu.style.height = 'auto';

          let height = _menu.offsetHeight,
            window_height = w.clientHeight;

          if (height < window_height) {
            height = window_height;
          }

          _menu.setAttribute('style', '');
          menuInit.page_wrapper.setAttribute('height', height);
        }

      }


      // BIND OPEN MENU TRIGGER
      this.responsive_trigger.addEventListener('click', function (e) {
        e.preventDefault();

        responsive_menu.classList.add('zn-menu-visible');
        set_height();

      });

      // ADD ARROWS TO SUBMENUS TRIGGERS
      const itemsWithSubmenu = responsive_menu.querySelectorAll(".menu-item-has-children");
      if (itemsWithSubmenu.length > 0) {
        for (let i = 0; i < itemsWithSubmenu.length; i++) {
          const item = itemsWithSubmenu[i];
          item.classList.add('zn_res_has_submenu');
          const subTrigger = '<span class="zn_res_submenu_trigger"><i class="fal fa-angle-right"></i></span>';
          item.insertAdjacentHTML('afterbegin', subTrigger);
        }
      }
      // ADD BACK BUTTONS
      const submenuList = responsive_menu.querySelectorAll('.zn_res_has_submenu > ul'),
        mainMenu = d.getElementById('zn-res-menu');

      mainMenu.insertAdjacentHTML("afterbegin", this.close_text);
      for (let i = 0; i < submenuList.length; i++) {
        const submenu = submenuList[i];
        submenu.insertAdjacentHTML("afterbegin", this.back_text);
      }

      const backButtons = d.querySelectorAll('.zn_res_menu_go_back');
      for (let i = 0; i < backButtons.length; i++) {
        const backButton = backButtons[i];
        backButton.addEventListener('click', function (e) {
          e.preventDefault();
          const active_menu = this.closest('.zn-menu-visible');
          active_menu.classList.remove('zn-menu-visible');
          set_height();
          if (active_menu.id === 'zn-res-menu') {
            menuInit.page_wrapper.style.height = 'auto';
          }
        });
      }

      // OPEN SUBMENU'S ON CLICK
      const submenuTriggers = d.querySelectorAll('.zn_res_submenu_trigger');
      for (let i = 0; i < submenuTriggers.length; i++) {
        const submenuTrigger = submenuTriggers[i];
        submenuTrigger.addEventListener('click', function (e) {
          e.preventDefault();
          const siblings = this.parentNode.children;
          for (let j = 0; j < siblings.length; j++) {
            const sibling = siblings[j];
            sibling.classList.add('zn-menu-visible')
          }
          set_height();
        })
      }

      mainMenu.insertAdjacentHTML('beforeend', this.phone_text);

    },

    triggerMenu: function () {

      if (w.innerWidth < 1200) {
        if (!this.menu_activated) {
          this.start_responsive_menu();
          this.menu_activated = true;
        }
        this.page_wrapper.classList.add('zn_res_menu_visible');
      } else {
        // WE SHOULD HIDE THE MENU
        const visibleMenu = d.querySelector('.zn-menu-visible')
        if (visibleMenu) {
          visibleMenu.classList.remove('zn-menu-visible');
        }
        this.page_wrapper.style.height = 'auto';
        this.page_wrapper.classList.remove('zn_res_menu_visible');
      }
    },

    recurringMenuFunction: function () {
      this.triggerMenu();
      let menuClosed = false;
      if (w.innerWidth < 1200) {
        if (menuClosed) return;
        //@wpk
        // Close button for the responsive menu
        var closeMenuSender = d.querySelectorAll('.zn-close-menu-button, .zn-res-menu-overlay');
        if (closeMenuSender) {

          for (let i = 0; i < closeMenuSender.length; i++) {
            const sender = closeMenuSender[i];
            sender.addEventListener('click', function (e) {
              e.preventDefault();
              e.stopPropagation();
              let parent = d.getElementById('zn-res-menu');
              parent.classList.remove('zn-menu-visible');

              const visibleMenus = d.querySelectorAll('.zn-menu-visible');
              for (let j = 0; j < visibleMenus.length; j++) {
                const menu = visibleMenus[j];
                menu.classList.remove('zn-menu-visible');
              }
              d.getElementById('page_wrapper').style.height = 'auto';
            })
          }

        }
        menuClosed = true;
      }
    }

  }

  let testimonialCarousel = {

    init: function () {

      const carouselNodes = d.querySelectorAll('.carousel');
      const carouselOptions = {
        loop: true
      };
      let carouselArray = [];
      for (let i = 0; i < carouselNodes.length; i++) {
        const carouselNode = carouselNodes[i];
        carouselArray[i] = EmblaCarousel(carouselNode, carouselOptions);
        const autoplayer = this.autoplay(carouselArray[i]);
        const dots = carouselNode.querySelector('.carouselDots');
        const dotsArray = this.generateDotBtns(dots, carouselArray[i]);
        const setSelected = this.selectDotBtn(dotsArray, carouselArray[i]);
        this.setupDotBtns(dotsArray, carouselArray[i])
        carouselArray[i].on('init', autoplayer.play);
        carouselArray[i].on('init', setSelected);
        carouselArray[i].on('pointerDown', autoplayer.stop);
        carouselArray[i].on('select', setSelected);
      }
    },

    autoplay: function (carousel) {

      let timer = 0;
      const interval = 10000;

      const play = () => {
        stop();
        requestAnimationFrame(() => (timer = window.setTimeout(next, interval)));
      };

      const stop = () => {
        window.clearTimeout(timer);
        timer = 0;
      };

      const next = () => {
        if (carousel.canScrollNext()) {
          carousel.scrollNext();
        } else {
          carousel.scrollTo(0);
        }
        play();
      };

      return {play, stop};
    },

    setupDotBtns: function (dotsArray, embla) {
      if (dotsArray) {
        dotsArray.forEach((dotNode, i) => {
          dotNode.addEventListener("click", () => embla.scrollTo(i), false);
        });
      }
    },

    generateDotBtns: function (dots, embla) {
      if (dots) {
        const template = '<button class="embla__dot" type="button"></button>';
        dots.innerHTML = embla.scrollSnapList().reduce(acc => acc + template, "");
        return [].slice.call(dots.querySelectorAll(".embla__dot"));
      }
    },

    selectDotBtn: (dotsArray, embla) => () => {
      if (dotsArray) {
        const previous = embla.previousScrollSnap();
        const selected = embla.selectedScrollSnap();
        dotsArray[previous].classList.remove("is-selected");
        dotsArray[selected].classList.add("is-selected");
      }
    }

  }

  let inViewAnimations = {

    init: function () {

      const list = gsap.utils.toArray('.fameAnimate'),
        defaults = {
          fametype: "fadeFromLeft",
          famespeed: 1,
          fameintensity: 1,
          famedelay: 0,
          famereverse: 'false'
        },
        animationTypes = {
          'fadeFromLeft': [
            {x: -200, autoAlpha: 0},
            {x: 0, autoAlpha: 1}
          ],
          'fadeFromRight': [
            {x: 200, autoAlpha: 0},
            {x: 0, autoAlpha: 1}
          ],
          'fadeFromBottom': [
            {y: 200, autoAlpha: 0},
            {y: 0, autoAlpha: 1}
          ]
        }
      ;
      let elementIteration = 0;

      Array.prototype.forEach.call(list, function (element) {

        const options = Object.assign({}, defaults, element.dataset);
        const animation = gsap.fromTo(element, 1, animationTypes[options.fametype][0], animationTypes[options.fametype][1]);

        ScrollTrigger.create({
          animation: animation,
          trigger: element,
          start: function (self) {
            return self.trigger.getBoundingClientRect().y;
          }
        })

      });

    }

  }

  let parallaxItem = {

    init: function () {

      if (d.querySelectorAll('.parallaxItem').length < 1) {
        return;
      }

      const parallaxAnimation = gsap.to(
        '.parallaxItem',
        1,
        {yPercent: -10}
      )

      const parallaxTrigger = ScrollTrigger.create({
        animation: parallaxAnimation,
        trigger: '.parallaxItem',
        scrub: .2
      });

    }

  }

  w.addEventListener('DOMContentLoaded', domLoadedEvents);
  w.addEventListener('load', loadEvents);
  w.addEventListener('resize', resizeEvents);

  function domLoadedEvents() {
    setActiveMenuItem();
    menuInit.init();
    testimonialCarousel.init();
  }

  function loadEvents() {
    menuInit.recurringMenuFunction();
    if (Modernizr.es6object) {
      inViewAnimations.init();
      parallaxItem.init();
    }
  }

  function resizeEvents() {
    menuInit.recurringMenuFunction();
  }


})(window, document);
